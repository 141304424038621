<template>
  <div class="media-settings">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <h4>Image sizes</h4>
            <p class="card-description mb-0">
              The size listed below determine the maximum dimensions in pixels
              to use when adding an image to the <code>media library</code>
            </p>
          </div>
          <div class="card-body border-bottom">
            <div class="row mb-3 mt-3" v-for="item in imageSize" :key="item.id">
              <div class="col-md-4 col-12 col-lg-4">
                <label v-if="item.name === 'thumb-s'">Thumbnail size</label>
                <label v-else-if="item.name === 'thumb-m'">Medium size</label>
                <label v-else-if="item.name === 'thumb-r'">Large size</label>
              </div>
              <div class="col-md-4 col-12 col-lg-4">
                <div class="form-group row mb-2">
                  <div class="col-6 justify-content-center align-self-center">
                    <label class="mb-0"> Width </label>
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control form-control-media"
                      v-model="item.width"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-6 justify-content-center align-self-center">
                    <label class="mb-0"> Height </label>
                  </div>
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control form-control-media"
                      v-model="item.height"
                    />
                  </div>
                </div>
              </div>

              <!-- end col-->
            </div>
          </div>
          <div class="card-body border-bottom">
            <div class="row">
              <div class="col-12">
                <div class="float-right">
                  <button
                    type="button"
                    v-on:click="updateImageSize()"
                    class="btn btn btn-add btn-primary"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "../../components/Loader";
import { mediaApi } from "../../api";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
export default {
  name: "media-settings",
  components: {
    Loader,
  },
  data() {
    return {
      imageSize: null,
      showLoader: false,
    };
  },
  mounted() {
    this.imageSizes();
  },

  methods: {
    async imageSizes() {
      this.showLoader = true;
      const { data } = await mediaApi.imageSize();
      this.imageSize = data.data.data;
      this.showLoader = false;
    },

    updateImageSize() {
      this.imageSize.forEach((value, index) => {
        let body = { height: value.height, width: value.width };
        this.showLoader = true;
        axios
          .post(`${mediaApiURL}/settings/size/update/${value.id}`, body)
          .then((response) => {
            this.showLoader = false;
            if (response.data.code == 200) {
              if (index == this.imageSize.length - 1) {
                this.$swal({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  type: "success",
                  title: " ",
                  text: response.data.messages,
                });
              }
            } else {
              if (index == this.imageSize.length - 1) {
                this.$swal({
                  type: "error",
                  title: "Oops...",
                  text: response.data.messages,
                });
              }
            }
          });
      });
    },
  },
};
</script>



